import sha1 from './sha1/sha1'

// 数据排序处理
function sortASCII(data) {
  const d = Object.assign({}, data)
  d.signkey = 'iei7W3Gari3iqVuG' // 'BXT5MTDyIeEM2eAj'
  
  let keysList = [];
  for (let i in d) {
    if (i == null || i == undefined) continue;
    if (keysList.filter(v => {
      return v == i
    }).length > 0) continue;
    
    keysList.push(i)
  }
  
  let valuesList = []
  for (let k in keysList.sort()) {
    const item = d[keysList[k]]
    if (item == null || item == undefined) continue;
    
    // 依据Keys签名后的进行过滤，参数不去重 20220112
    // if (valuesList.filter(v => {
    // 	return v == item
    // }).length > 0) continue;
    
    if (item instanceof Array) {
      valuesList.push(JSON.stringify(item))
    }else {
      valuesList.push(item)
    }
  }
  // valuesList.push(signkey)
  console.log('待签名数据串：', valuesList.join(''))
  return valuesList.join('')
}


// 加密数据处理
function encryptedData(data) {
  const d = data || {}
  d.appId = 'wxa01abaaa98a643f6'
  d.ip = "127.0.0.1"
  d.machineNo = 'minipro'
  d.msgVersion = '1.0.0'
  d.platform = '10' // 10：小程序 20：公众号
  d.merId = window.merId // 'S9999073'  // 商户号
  d.storeId = window.storeId // 门店

  return {
    data: d,
    sign: sha1(sortASCII(d)),
    reqTime: (new Date()).getTime()
  }
}
  
export default {
  sortASCII,
  encryptedData
}

