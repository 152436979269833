import axios from 'axios';
import utils from '../utils/utils';
import { Toast } from 'vant';

// const SERVER_URL_REMOTE = 'http://dd.palcloud.cn/points-service'
const SERVER_URL_REMOTE = 'https://m.jifen.palcloud.cn/points-service'
// const SERVER_URL_REMOTE = 'http://10.0.2.117:18085/points-service'
const SERVER_URL_TEST = 'http://test-jf-h5.palcloud.cn/points-service'
const SERVER_URL_LOCAL = 'http://localhost:18083/jeecg-boot'
// create an axios instance
const service = axios.create({
  baseURL: SERVER_URL_REMOTE, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})


// request interceptorw
service.interceptors.request.use(
  config => {
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    config.data = utils.encryptedData(config.data)

    // do something before request is sent
    // eslint-disable-next-line no-undef
    config.headers['X-Access-Token'] = localStorage.getItem('ljj_login_token') || ''
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    console.log("--------1333")

    // if the custom code is not 20000, it is judged as an error.
    if (res.code === 500) {
      if (res.result) {
        //if (res.result.resultCode == 'B002' || res.result.resultCode == 'B003' || res.result.resultCode == 'B007') {
        if (res.result.resultCode != '9999' && res.result.authUrl && res.result.authUrl.length > 0) {
          Toast.clear()
          return res.result
        }else {
          Toast(res.message || 'Error');
          return Promise.reject(res)
        }
      }else {
        Toast(res.message || 'Error');
        return Promise.reject(res)
      }
    } else if (res.code !== 200) {
      Toast(res.message || 'Error');
      return Promise.reject(res)
    } else {
      Toast.clear()
      return res.result
    }
  },
  error => {
    if (error.response && error.response.status == 401) {
      // window.location = "/"
      return Promise.reject(error.response)
    }

    Toast(error.message || 'Error');
    return Promise.reject(error)
  }
)

export default service
