import request from "@/libs/request"

// 根据手机号登录注册
//  POST
// /jeecg-boot/app/api/authLogin
export function authLogin(data) {
    return request({
        url: '/app/api/authLogin',
        method: 'post',
        data
    })
}

// 根据手机号登录注册
//  POST
// /jeecg-boot/app/api/authLoginByCode
export function authLoginByCode(data) {
    return request({
        url: '/app/api/authLoginByCode',
        method: 'post',
        data
    })
}

// 获取用户信息
//  POST
// /jeecg-boot/app/api/memberInfo
export function memberInfo(data) {
    return request({
        url: '/app/api/memberInfo',
        method: 'post',
        data
    })
}


// 查询移动积分
//  POST
// /jeecg-boot/app/api/queryCmccBalance
export function queryCmccBalance(data) {
    return request({
        url: '/app/api/queryCmccBalance',
        method: 'post',
        data
    })
}


// 获取商品接口
//  POST
// /jeecg-boot/app/api/getProduct
export function getProduct(data) {
    return request({
        url: '/app/api/getProduct',
        method: 'post',
        data
    })
}

// 下单接口
//  POST
// /jeecg-boot/app/api/placeOrder
export function placeOrder(data) {
    return request({
        url: '/app/api/placeOrder',
        method: 'post',
        data
    })
}

// /app/api/placeOrderByCode
export function placeOrderByCode(data) {
    return request({
        url: '/app/api/placeOrderByCode',
        method: 'post',
        data
    })
}

// 支付接口
//  POST
// /jeecg-boot/app/api/dectOrder
export function dectOrder(data) {
    return request({
        url: '/app/api/dectOrder',
        method: 'post',
        data
    })
}


// 获取短信接口
//  POST
// /jeecg-boot/app/api/sendCmccSms
export function sendCmccSms(data) {
    return request({
        url: '/app/api/sendCmccSms',
        method: 'post',
        data
    })
}

// 获取门店和渠道信息
//  POST
// /points-service/app/api/base/getStoreChannelInfo
export function getStoreChannelInfo(data) {
    return request({
        url: '/app/api/base/getStoreChannelInfo',
        method: 'post',
        data
    })
}

// 获取订单记录
//  POST
// /points-service/app/api/getOrderListPage
export function getOrderListPage(data) {
    return request({
        url: '/app/api/getOrderListPage',
        method: 'post',
        data
    })
}

// 获取发货信息
//  POST
// /points-service/app/api/send/getSendDeliverInfo
export function getSendDeliverInfo(data) {
    return request({
        url: '/app/api/send/getSendDeliverInfo',
        method: 'post',
        data
    })
}


// 查询移动积分-绑定不跳转
//  POST
// /points-service/app/api/queryCmccBalanceBind
export function queryCmccBalanceBind(data) {
    return request({
        url: '/app/api/queryCmccBalanceBind',
        method: 'post',
        data
    })
}

export function queryCmccBalanceNew(data) {
    return request({
        url: '/app/api/queryCmccBalanceNew',
        method: 'post',
        data
    })
}

// 畅由移动绑定重新获取验证码
//  POST
// /points-service/app/api/getBindMsgCode
export function getBindMsgCode(data) {
    return request({
        url: '/app/api/getBindMsgCode',
        method: 'post',
        data
    })
}


// 畅由移动绑定提交
//  POST
// /points-service/app/api/bindInfoCmcc
export function bindInfoCmcc(data) {
    return request({
        url: '/app/api/bindInfoCmcc',
        method: 'post',
        data
    })
}

// 获取门店信息
// POST
// /app/api/getStoreInfo
export function getStoreInfo(data) {
    return request({
        url: '/app/api/getStoreInfo',
        method: 'post',
        data
    })
}